import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import Icon from "./icons";
import Pagination from "./pagination";

import Input from "./input";

import SelectMultiple from "./selectMultiple";
import EmptySearch from "./emptySearch"

const moment = require("moment");

const TableRecentOrders = (props) => {
  console.log(props.dataTable)
  const tableRecentRef = useRef(null);
  let selectMultiple = useRef(null);
  const [filters, setFilters] = useState({start_date_activity: "", end_date_activity: "", filter_type: ""})
  const [forceUpdate, setForceUpdate] = useState(false)

  useEffect(() => {
    prepare_dates()
    setTimeout(() => {
      tableRecentRef.current.style.opacity = 1
    }, 0)
  }, []);
  

  const prepare_dates = () => {
    let current_date = new Date();
    let current_tomorrow = new Date();
    current_tomorrow = current_tomorrow.setDate(current_tomorrow.getDate() + 1)
    current_tomorrow = moment(current_tomorrow).toDate()

    let start_date = filters.hasOwnProperty("start_date") ? filters["start_date"] : ""
    let end_date = filters.hasOwnProperty("end_date") ? filters["end_date"] : ""
    
    start_date = moment(start_date).format("MM/DD/YYYY")
    end_date = moment(end_date).format("MM/DD/YYYY")

    if (start_date === "Invalid date") start_date = ""
    if (end_date === "Invalid date") end_date = ""

    return{
      start_date_activity: start_date, 
      end_date_activity: end_date
    }

  }

  const getInputValue = (value, bigger, element_id) => {
    
    let data = filters;
    if (element_id === "start_date_activity") {
      data.start_date = moment(value).format("MM/DD/YYYY");
    } else if (element_id === "end_date_activity") {
      data.end_date = moment(value).format("MM/DD/YYYY");
    }
    data[element_id] = value
    setFilters(data)
    setForceUpdate(!forceUpdate)
  }

   const renderFilters = () => {
    return (
      <div className="wrapper-filter">
        <div className="container-filter-inputs">
          <div className="wrapper-form-item">
            <span className="input-label">{props.filters_internalization ? props.filters_internalization["start_date"] : "Start date"}</span>
            <Input
              colorGrey={true}
              id={'start_date_activity'}
              type={'date'}
              name={"start_date_activity"}
              value={filters['start_date_activity']}
              defaultValue=""
              formData={{}}
              onChangeInput={(date, bigger) =>
                  getInputValue(date, bigger, "start_date_activity")
              }
              prepare_dates={prepare_dates()}
            />
          </div> 

          <div className="wrapper-form-item">
            <span className="input-label">{props.filters_internalization ? props.filters_internalization["end_date"] : "End date"}</span>
            <Input
              colorGrey={true}
              id={'end_date_activity'}
              type={'date'}
              name={"end_date_activity"}
              value={filters['end_date_activity']}
              defaultValue=""
              formData={{}}
              onChangeInput={(date, bigger) =>
                  getInputValue(date, bigger, "end_date_activity")
              }
              prepare_dates={prepare_dates()}
            />
          </div>              
          <SelectMultiple
            colorGrey={true}

            setRef={ref => (selectMultiple = ref)}
            label={props.filters_internalization ? props.filters_internalization["filter_type"] : "Filter type"}
            defaultValue={filters["filter_type"] !== "" ? filters["filter_type"].name : props.filters_internalization ? props.filters_internalization["filter_placeholder"] : "Select"}
            options={props.filters_internalization ? props.filters_internalization["filter_options"] : [{value: "Sell", name: "Sell"}, {value: "Buy", name: "Buy"}, {value: "Deposit", name: "Deposit"}, {value: "Withdrawal", name: "Withdrawal"}, {value: "Payment", name: "Payment"}]}
            disabled={false}
            multiple={false}
            openOptions={() => console.log("openOptions")}
            modalOptions={false}
            asName={"name"}
            getOptions={item => {
              setFilterAndSearch(item)
            }}
            itemsSelected={filters["filter_type"] || []}
          /> 
          <div className="wrapper-btn">
            <div
              className="icon-notification"
              onClick={() => goToPage()}
            >
              <Icon
                name="Search"
                width={20}
                height={20}

              />
            </div>
            <div
              className="icon-notification"
              onClick={() => {
                setFilters({start_date_activity: "", end_date_activity: "", filter_type: ""})
                setTimeout(() => {goToPage(1, true)},300)
              }}
            >
              <Icon
                name="Close"
                close={true}
                width={16}
                height={16}

              />
            </div>
          </div>
        </div>
      </div>
    )
  } 

  const setFilterAndSearch = (item) => {
    setFilters({...filters, filter_type: {name: item.item.name, value: item.item.value}})
    props.filteredCall("?page=1"+"&start_date="+(filters["start_date"] || "")+"&end_date="+(filters["end_date"] || "")+"&filter_type="+item.item.value)   
  }
  const rowCells = (rows, indexCell, cell) => {
    let cl = classNames(
      "c-table-recent-orders__cell",
      !isLastCell(rows, indexCell)
        ? "c-table-recent-orders__cell--border"
        : "c-table-recent-orders__cell--flex-start",
      cell.highlighted && "c-table-recent-orders__cell--highlighted"
    );

    return cl;
  };

  const getColorCell = (cell) => {
    return "c-table-recent-orders__cell-tag--gray"
    if ([11, 14, 3, 2, 4].includes(cell.real_type)){
      return "c-table-recent-orders__cell-tag--orange"
    }else if([0,1,10,12].includes(cell.real_type)){
      return "c-table-recent-orders__cell-tag--blue"
    }else{
      return "c-table-recent-orders__cell-tag--gray"
    }
  }

  const rowCellItemTag = (cell) => {
    let cl = classNames(
      "c-table-recent-orders__cell-tag",
      getColorCell(cell)
    );

    return cl;
  };

  const rowCellItemText = (cell) => {
    let cl = classNames(
      "c-table-recent-orders__cell-text",
      cell.highlighted && "c-table-recent-orders__cell-text--bold"
    );

    return cl;
  };

  const isLastCell = (rows, index) => {
    return rows.length - 1 === index;
  };
  
  const goToPage = (page = 1, cleaned = false) => {
    if(cleaned){
      props.filteredCall("?page="+page)
    }else{
      props.filteredCall("?page="+page+"&start_date="+(filters["start_date"] || "")+"&end_date="+(filters["end_date"] || "")+"&filter_type="+filters["filter_type"].value)   
    }
  }

  return (
    <div ref={tableRecentRef} className="c-table-recent-orders">
      {renderFilters()}
      {props.dataTable.map((rows, indexRow) => {
        return (
          <div className="c-table-recent-orders__row">
            {rows.map((cell, indexCell) => {
              if (isLastCell(rows, indexCell)) {
                return (
                  <div className={rowCells(rows, indexCell, cell)}>
                    <p className="c-table-recent-orders__cell-text c-table-recent-orders__cell-text--bold">{cell.value.first}</p>
                    <p>{cell.value.second}</p>
                  </div>
                );
              } else {
                return (
                  <div className={rowCells(rows, indexCell, cell)}>
                    {cell.type ? (
                      <p className={rowCellItemTag(cell)}>{cell.value}</p>
                    ) : (
                      <p className={rowCellItemText(cell)}>{cell.value}</p> 
                    )}
                  </div>
                );
              }
            })}
          </div>
        );
      })}
      {
        props.dataTable.length === 0 &&
        <EmptySearch nameIcon="BoxEmpty" text="No data" />
      }
       <Pagination
          goTo={page => goToPage(page)}
          pages={Array.from(
            { length: props.totalPages },
            (v, k) => k + 1
          )}
          currentPage={props.currentPage}
        />
    </div>
  );
};

export default TableRecentOrders;
