import { useState, useRef } from 'react'
import PieHighcharts from "../../components/PieHighcharts";
import HeroFinancial from './heroFinancial';

const Hero = (props) => {

  const {introCards, lang, pieHighChartData, pieAssetClassData, lastMarketClose } = props

  const [tooltip, setTooltip] = useState(null);

  const tooltipRef = useRef(null);

  const pieSelected = (pie, nested) => {
    console.log(pie, nested);
  }

  const renderTooltip = () => {
    return (
      <div
        ref={tooltipRef}
        className="container-tooltip container-tooltip--no-triangle c-dashboard-hero__tooltip"
        onClick={() => setTooltip(null)}
      >
        {tooltip && (
          <p
            style={{ textAlign: "center" }}
            dangerouslySetInnerHTML={{ __html: tooltip.text }}
          ></p>
        )}
      </div>
    )
  }

  return (
    <div className="c-dashboard-hero__container">

      {/* Last market */}
      <div class="table-preheader">
        <p>
          <strong>
            <span>{lastMarketClose.title}:</span> {lastMarketClose.amount}
          </strong>
        </p>
      </div>

      <div style={{ display: "flex" }}>
        {/* Content cards */}
        <div className="c-dashboard-hero__cards">
          <HeroFinancial 
            introCards={introCards} 
            tooltip={tooltip} 
            setTooltip={setTooltip} 
            tooltipRef={tooltipRef}
            lang={lang}
          />
        </div>
        {tooltip && renderTooltip(tooltip)}

        {/* Charts */}
        <div className="c-dashboard-hero__charts">
          <div className="c-dashboard-hero__chart">
            <PieHighcharts
              pieSelected={(pie) => pieSelected(pie)}
              data={pieHighChartData}
              size={112}
              chartClassName="c-chart-pie-dashboard"
            />
          </div>

          <div className="c-dashboard-hero__chart">
            <PieHighcharts
              pieSelected={(pie) => pieSelected(pie)}
              data={pieAssetClassData}
              size={112}
              chartClassName="c-chart-pie-dashboard"
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default Hero
