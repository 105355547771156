import HeroFinancialCard from "./heroFinancialCard";

const HeroFinancial = (props) => {
  const { introCards, lang, setTooltip, tooltip, tooltipRef } = props
  return (
    <div className="c-dashboard-hero__financial-container">
      {introCards.map((item, index) => {
        return (
          <HeroFinancialCard
            item={item}
            lang={lang}
            setTooltip={(item, parent) => {
              // Set tooltip content
              if (!tooltip) {
                setTooltip(item.total_aum.tooltip);
              } else {
                if (tooltip.title && tooltip.title !== item.title) {
                  setTooltip(item.total_aum.tooltip);
                } else {
                  setTooltip(null);
                }
              }

              // Show tooltip
              setTimeout(() => {
                tooltipRef.current.style.opacity = 1;
                tooltipRef.current.style.top = parent.current.offsetTop + "px";
                tooltipRef.current.style.left = parent.current.offsetLeft + "px";
              }, 500);
            }}
          />
        );
      })}
    </div>
  )
}

export default HeroFinancial
